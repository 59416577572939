import React from "react"

import { Indent } from "src/uikit"

import * as S from "./styled"

export const chunkArray = (arr, chunkSize) => {
  const results = []

  while (arr.length) {
    results.push(arr.splice(0, chunkSize))
  }

  return results
}

const Partners = ({ logos }) => {
  const mobileChunks = chunkArray(logos.slice(), 2)

  return (
    <S.Container id="partners">
      <Indent lg={80} md={80} sm={64} />
      <div className="inner">
        {logos.map(logo => (
          <div key={logo.id} className="box">
            <S.Logo src={logo.file.url} alt={logo.description} />
          </div>
        ))}
      </div>
      <div className="inner mobile">
        {mobileChunks.map((chunk, i) => (
          <div key={i} className="logos-row">
            {chunk.map(logo => (
              <S.Logo
                className="lazyload"
                key={logo.id}
                data-src={logo.file.url}
                alt={logo.description}
              />
            ))}
          </div>
        ))}
      </div>
    </S.Container>
  )
}

export default Partners
