import styled from "styled-components"

import { media } from "src/uikit"

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;

  .inner {
    width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inner.mobile {
    display: none;

    flex-direction: column;
    align-items: center;
  }

  .logos-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 237px;
    margin-bottom: 30px;

    :last-child {
      margin-bottom: 0;
    }
  }

  .box {
    margin: 0 27.5px;
  }

  @media (max-width: 1100px) {
    .inner {
      width: 688px;
      height: auto;
      flex-wrap: wrap;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 173px;
      margin: 0;
    }

    .box:first-child,
    .box:nth-child(2),
    .box:nth-child(3) {
      margin-bottom: 48px;
    }
  }

  @media ${media.mobile} {
    padding-left: 16px;
    padding-right: 16px;

    .inner {
      max-width: 100%;
      display: none;
    }

    .inner.mobile {
      display: flex;
    }

    .box {
      flex-basis: 45%;
      margin-bottom: 16px !important;
    }
  }
`

export const Logo = styled.img`
  max-width: 100%;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }

  @media (max-width: 1100px) {
    margin: 0;
  }
`
